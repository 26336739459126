@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");

.App {
  align-items: center;
  justify-content: center;
}
body {
  background-color: #cbcbf3;
}
.title h1 {
  color: #3a3a3a;
  text-align: center;
  margin: 30px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
form {
  max-width: 500px;
  margin: 0 auto;
  background: white;
  padding: 10px 20px;
  margin-top: 50px;
  border-radius: 5px;
  box-shadow: 1px 2px 10px #ddd;
}
form h3 {
  text-align: center;
}
form input {
  width: 100%;
  padding: 10px 5px;
  outline: none;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.input_container {
  margin-bottom: 20px;
}
.btn_container {
  text-align: center;
  margin-bottom: 10px;
}
.btn_container button {
  background: #333;
  color: white;
  padding: 10px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.todo_container {
  width: 50%;
  margin: 0 auto;
}
.todo {
  margin-top: 20px;
  background: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 2px 10px #ddd;
}
.todo input {
  cursor: pointer;
  width: 60%;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.button-complete,
.button-delete,
.button-edit {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
}
#i {
  font-size: 35px;
}
.button-complete {
  color: #95faa3;
  margin-right: 10px;
}
.button-edit {
  color: #56cfff;
  margin-right: 10px;
}
.button-delete {
  color: rgb(255, 122, 98);
}
@media screen and (max-width: 667px) {
  .task_container {
    width: 90%;
  }
  .task {
    margin-right: 0px;
  }
  .todo_container {
    width: 100%;
  }
  .todo input {
    width: 86%;
  }
}
input:focus,
button:focus {
  outline: none;
}
